<template>
    <RouterView />
</template>

<script>
import { RouterView } from 'vue-router';

export default {
  name: 'App',
  components: {
    RouterView
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/style.scss";
</style>