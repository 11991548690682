<template>
    <div class="app-wrap">
        <div v-if="isLoading" class="se-pre-con"></div>
        <img alt="Gomobile logo" src="@/assets/logo.svg" class="company-logo" />
        <form v-if="!showThanks" action="#">
            <div class="paragraph-wrap">
                <h1 class="text-bold text-primary no-top-margin">{{ $t("main.header") }}</h1>
                <p class="paragraph">
                    <i18next :translation="$t('main.text1')">
                        <template #shortFormLink>
                            <RouterLink
                                class="orange-text"
                                :to="{
                                    path: '/short-form',
                                    query: $route.query,
                                }"
                                >{{ $t("main.shortFormHere") }}</RouterLink
                            >
                        </template>
                    </i18next>
                </p>
                <p class="paragraph">{{ $t("main.text2") }}</p>
                <h2 class="text-bold text-primary">{{ $t("name.header") }}</h2>
                <TextareaAutosize
                    :class="screenWidth > 768 ? 'feedback-textarea desktop-textarea' : 'feedback-textarea'"
                    v-model="feedbackData.name"
                    type="text"
                    :min-height="10"
                    :max-height="350"
                    :placeholder="$t('name.placeholder')"
                />
                <h2 :class="{ alertLine: isAlert }" class="top-wrap text-bold" id="must-answer">
                    <span class="text-primary custom-asterisk">*</span>
                    {{ $t("fullRating.header") }}
                    <span v-if="isAlert" class="text-primary custom-asterisk">({{ $t("isNecessary.text") }})</span>
                </h2>
                <ThePointsbar
                    @change="feedbackData.fullRating = $event"
                    @alert="isAlert3 = $event"
                    :alertLine="isAlert"
                    :size="10"
                    code="full"
                    :full-name="true"
                />
                <!-- <h2 :class="{ alertLine: isAlert3 }" class="top-wrap text-bold" id="must-answer-recomend">
                    <span class="text-primary custom-asterisk">*</span>
                    {{ $t('recommendation.header') }}
                    <span v-if="isAlert3" class="text-primary custom-asterisk">({{ $t('isNecessary.text') }})</span>
                </h2>
                <ThePointsbar @change="feedbackData.recomendation = $event" :size="10" :alertLine="isAlert3" :rate-labe="false" /> -->
                <h2 class="text-bold text-primary">{{ $t("units.header") }}</h2>
                <p class="paragraph">
                    {{ $t("units.text") }}
                </p>
                <h2 :class="{ alertLine: isAlert2 }" class="text-bold" id="must-answer-work" v-if="false">
                    <span class="text-primary custom-asterisk"></span>
                    <span>{{ $t("units.isNecessary.text") }}</span>
                    <span v-if="isAlert2" class="text-primary custom-asterisk">({{ $t("units.isNecessary.quantity") }})</span>
                </h2>
                <TheEvaluation
                    v-for="(unit, unitName, index) in $t('units.values', { returnObjects: true })"
                    :key="index"
                    @change="feedbackData.depts[unitName] = $event"
                    :main-text="unit.header"
                    :secondary-text="unit.text"
                    :hint="$t('units.hint')"
                    is-optional="true"
                />
            </div>
            <div class="paragraph-wrap">
                <!-- <h2 class="text-bold text-primary">Насколько мы выполняем KPIs?</h2>
                <ThePointsbar @change="feedbackData.kpiRating = $event" :size="10" /> -->

                <!-- <h2 class="text-bold text-primary">{{ $t('origin.header') }}</h2> -->
                <!-- <TheCheckbox v-for="(value, index) in $t('origin.values', { returnObjects: true })" :key="index" @change="addHowFound" :text="value" name="howFound" /> -->

                <!-- <TheCheckbox @change="addHowFound" :text="$t('origin.values.1')" name="howFound" />
                <TheCheckbox @change="addHowFound" :text="$t('origin.values.2')" name="howFound" />
                <TheCheckbox @change="addHowFound" :text="$t('origin.values.3')" name="howFound" />
                <TheCheckbox @change="addHowFound" :text="$t('origin.values.4')" name="howFound" />
                <TheCheckbox @change="addHowFound" :text="$t('origin.values.5')" name="howFound" />
                <TheCheckbox @change="addHowFound" :text="$t('origin.values.6')" name="howFound" /> -->
                <TextareaAutosize
                    v-if="isActiveOther"
                    :class="screenWidth > 768 ? 'feedback-textarea desktop-textarea' : 'feedback-textarea'"
                    v-model="otherHowFound"
                    :min-height="10"
                    :max-height="350"
                    name="other"
                    :placeholder="$t('origin.other')"
                />
                <h2 class="top-wrap text-bold text-primary">{{ $t("personalFeedback.header") }}</h2>
                <TextareaAutosize
                    :class="screenWidth > 768 ? 'feedback-textarea desktop-textarea' : 'feedback-textarea'"
                    v-model="feedbackData.personalFeedback"
                    :min-height="10"
                    :max-height="350"
                    name="personal_feedback"
                />
                <h2 class="top-wrap text-bold text-primary">{{ $t("improve.header") }}</h2>
                <TextareaAutosize
                    :class="screenWidth > 768 ? 'feedback-textarea desktop-textarea' : 'feedback-textarea'"
                    v-model="feedbackData.feedback"
                    :min-height="10"
                    :max-height="350"
                    name="become_better"
                    :placeholder="$t('improve.placeholder')"
                />
            </div>
            <button type="button" class="submit-button" @click="sendFeedback">
                <span>{{ $t("sendBtn") }}</span
                ><img src="@/assets/message.svg" />
            </button>
        </form>
        <div v-else class="thanks-text">
            <h1>{{ $t("thanks.header") }}</h1>
            <p>{{ $t("thanks.text1") }}</p>
            <p v-if="$i18next.language === 'ru'">
                {{ $t("thanks.text2") }} (<a class="enable-select" :href="`mailto: ${$t('thanks.email1')}`">{{ $t("thanks.email1") }}</a
                >) {{ $t("thanks.text3") }} (<a class="enable-select" :href="`mailto: ${$t('thanks.email2')}`">{{ $t("thanks.email2") }}</a
                >).
            </p>
            <p v-else>
                {{ $t("thanks.text2") }} <a class="enable-select" :href="`mailto: ${$t('thanks.email1')}`">{{ $t("thanks.email1") }}</a>
            </p>
        </div>
    </div>
</template>

<script>
    import TheEvaluation from "@/components/TheEvaluation.vue";
    import ThePointsbar from "@/components/ThePointsbar";
    // import TheCheckbox from "@/components/TheCheckbox";
    import { RouterLink } from "vue-router";
    import TextareaAutosize from "@/components/TextareaAutosize.vue";
    import API from "@/api";
    import i18next from "i18next";

    export default {
        name: "App",
        components: {
            // TheCheckbox,
            TheEvaluation,
            ThePointsbar,
            TextareaAutosize,
            RouterLink,
        },
        data: function () {
            return {
                otherHowFound: "",
                showThanks: false,
                isLoading: false,
                isAlert: false,
                isAlert2: false,
                isAlert3: false,
                feedbackData: {
                    // table: "simple",
                    table: process.env.VUE_APP_LANGUAGE === "en" ? "ww" : "simple",
                    version: "v2",
                    name: "",
                    fullRating: "",
                    depts: {
                        clientService: {
                            rating: "",
                            feedback: "",
                        },
                        finance: {
                            rating: "",
                            feedback: "",
                        },
                        mediaBuying: {
                            rating: "",
                            feedback: "",
                        },
                        aso: {
                            rating: "",
                            feedback: "",
                        },
                        design: {
                            rating: "",
                            feedback: "",
                        },
                        analytics: {
                            rating: "",
                            feedback: "",
                        },
                        strategy: {
                            rating: "",
                            feedback: "",
                        },
                    },
                    kpiRating: "",
                    personalFeedback: "",
                    howFound: [],
                    feedback: "",
                    paramName: "",
                },
            };
        },

        methods: {
            sendFeedback: async function () {
                if (this.isActiveOther) {
                    const index = this.feedbackData.howFound.indexOf(i18next.t("origin.values", { returnObjects: true }).at(-1)); // index of "other" option
                    this.feedbackData.howFound[index] = this.otherHowFound;
                }
                // this.feedbackData.howFound = this.feedbackData.howFound.join("; ");
                if (this.feedbackData.fullRating.length === 0) {
                    document.getElementById("must-answer").scrollIntoView({ block: "center", behavior: "smooth" });
                    this.isAlert = true;
                    return;
                }
                // if (this.feedbackData.recomendation.length === 0) {
                //     document.getElementById("must-answer-recomend").scrollIntoView({ block: "center", behavior: "smooth" });
                //     this.isAlert3 = true;
                //     return;
                // }
                const deep = this.feedbackData.depts;
                let arrDeep = [];
                for (let key in deep) {
                    console.log(key);
                    if (deep[key].rating.length != 0) {
                        arrDeep.push(deep[key]);
                    }
                }
                // if (arrDeep.length === 0) {
                //     document
                //         .getElementById("must-answer-work")
                //         .scrollIntoView({ block: "center", behavior: "smooth" });
                //     this.isAlert2 = true;
                //     return;
                // }

                this.isLoading = true;
                const that = this;
                try {
                    if (this.feedbackData.paramName === "") {
                        this.feedbackData.paramName = "Клиент стер уникальный параметр ссылки";
                    }
                    const api = new API();
                    await api.push(this.feedbackData).then(function () {
                        that.isLoading = false;
                    });
                    that.isLoading = false;
                } catch (e) {
                    console.error(e);
                }
                this.showThanks = true;
            },
            getData: async function () {
                try {
                    const res = await fetch(
                        "https://script.google.com/macros/s/AKfycbwTzMHA7A4lEapDbm0NZETHh8T8Ff4g91VOUMMuBSXPlXnn6MEWLDH24sE5SipktjeiYA/exec"
                    );
                    let data = await res.json();
                    const locat = location;

                    if (i18next.language === "ru") {
                        let index = 0;
                        let dopIndex = 0;
                        for (let i = 0; i < data.length; i++) {
                            if ("GoAHead" === data[i][0]) {
                                index = i;
                            } else if ("GoAHeadEn" === data[i][0]) {
                                dopIndex = i;
                            }
                        }
                        data = data.slice(index + 1, dopIndex);
                        for (let i = 0; i < data.length; i++) {
                            let param = data[i][1].slice(24);

                            if (param == locat.search) {
                                this.feedbackData.paramName = data[i][0];
                            }
                        }
                    } else {
                        let index = 0;

                        for (let i = 0; i < data.length; i++) {
                            if ("GoAHeadEn" === data[i][0]) {
                                index = i;
                            }
                        }

                        data = data.slice(index + 1);
                        for (let i = 0; i < data.length; i++) {
                            let param = data[i][1].slice(24);

                            if (param == locat.search) {
                                this.feedbackData.paramName = data[i][0];
                            }
                        }
                    }
                } catch (err) {
                    console.log(err);
                }
            },
            addHowFound(val) {
                if (val.bool) {
                    this.feedbackData.howFound.push(val.text);
                } else {
                    this.feedbackData.howFound = this.feedbackData.howFound.filter((el) => {
                        return el != val.text;
                    });
                }
                // console.log(val);
            },
        },
        computed: {
            screenWidth() {
                return window.innerWidth;
            },
            isActiveOther() {
                if (this.feedbackData.howFound.includes(i18next.t("origin.values", { returnObjects: true }).at(-1))) {
                    return true;
                }
                return false;
            },
        },
        mounted() {
            this.getData();
        },
    };
</script>
