<template>
    <div class="main">
        <img alt="Gomobile logo" src="@/assets/logo.svg" class="company-logo" />
        <form action="#" v-if="!showThanks">
            <div class="app-wrap">
                <div v-if="isLoading" class="se-pre-con"></div>
                <h1 class="text-bold text-primary no-top-margin">{{ $t("shortForm.header") }}</h1>
                <div class="feedback-container">
                    <label class="label" for="feedback">{{ $t("shortForm.namePlaceholder") }}</label>
                    <TextareaAutosize
                        :class="screenWidth > 576 ? 'feedback-textarea desktop-textarea' : 'feedback-textarea'"
                        v-model="feedbackData.name"
                        type="text"
                        :min-height="10"
                        :max-height="350"
                        :placeholder="$t('shortForm.namePlaceholder')"
                    />
                </div>
                <div class="feedback-container">
                    <label class="label" for="feedback"><span class="red">*</span> {{ $t("shortForm.feedbackPlaceholder") }}</label>
                    <TextareaAutosize
                        id="feedback"
                        :class="screenWidth > 576 ? 'feedback-textarea desktop-textarea' : 'feedback-textarea'"
                        v-model="feedbackData.feedback"
                        type="text"
                        :min-height="10"
                        :max-height="350"
                        :placeholder="$t('shortForm.feedbackPlaceholder')"
                    />
                </div>
                <div class="form-bottom">
                    <p class="form-bottom__legend paragraph">
                        <span class="red">*</span>
                        {{ $t("shortForm.requiredFields") }}
                    </p>
                    <button @click="sendFeedback" type="button" class="form-bottom__submit-button submit-button">
                        <span>{{ $t("sendBtn") }}</span
                        ><img src="@/assets/message.svg" />
                    </button>
                </div>
            </div>
        </form>
        <div v-else class="thanks-text">
            <h1>{{ $t("thanks.header") }}</h1>
            <p>{{ $t("thanks.text1") }}</p>
            <p v-if="$i18next.language === 'ru'">
                {{ $t("thanks.text2") }} (<a class="enable-select" :href="`mailto: ${$t('thanks.email1')}`">{{ $t("thanks.email1") }}</a
                >) {{ $t("thanks.text3") }} (<a class="enable-select" :href="`mailto: ${$t('thanks.email2')}`">{{ $t("thanks.email2") }}</a
                >).
            </p>
            <p v-else>
                {{ $t("thanks.text2") }} <a class="enable-select" :href="`mailto: ${$t('thanks.email1')}`">{{ $t("thanks.email1") }}</a>
            </p>
        </div>
    </div>
</template>

<script>
    import TextareaAutosize from "./TextareaAutosize.vue";
    import API from "@/api";
    import i18next from "i18next";

    export default {
        name: "ShortForm",
        components: {
            TextareaAutosize,
        },
        data: () => ({
            screenWidth: window.innerWidth,
            showThanks: false,
            isLoading: false,
            isAlert: false,
            feedbackData: {
                table: process.env.VUE_APP_LANGUAGE === "en" ? "ww" : "simple",
                name: "",
                feedback: "",
                version: "v2",
                isShort: true,
                paramName: "",
            },
        }),
        methods: {
            sendFeedback: async function () {
                if (this.feedbackData.feedback.length === 0) {
                    document.getElementById("must-answer").scrollIntoView({ block: "center", behavior: "smooth" });
                    this.isAlert = true;
                    return;
                }

                this.isLoading = true;
                const that = this;
                try {
                    if (this.feedbackData.paramName === "") {
                        this.feedbackData.paramName = "Клиент стер уникальный параметр ссылки";
                    }
                    const api = new API();
                    await api.push(this.feedbackData).then(function () {
                        that.isLoading = false;
                    });
                    that.isLoading = false;
                } catch (e) {
                    console.error(e);
                }
                this.showThanks = true;
            },
            getData: async function () {
                try {
                    const res = await fetch(
                        "https://script.google.com/macros/s/AKfycbwTzMHA7A4lEapDbm0NZETHh8T8Ff4g91VOUMMuBSXPlXnn6MEWLDH24sE5SipktjeiYA/exec"
                    );
                    let data = await res.json();
                    const locat = location;

                    if (i18next.language === "ru") {
                        let index = 0;
                        let dopIndex = 0;
                        for (let i = 0; i < data.length; i++) {
                            if ("GoAHead" === data[i][0]) {
                                index = i;
                            } else if ("GoAHeadEn" === data[i][0]) {
                                dopIndex = i;
                            }
                        }
                        data = data.slice(index + 1, dopIndex);
                        for (let i = 0; i < data.length; i++) {
                            let param = data[i][1].slice(24);

                            if (param == locat.search) {
                                this.feedbackData.paramName = data[i][0];
                            }
                        }
                    } else {
                        let index = 0;

                        for (let i = 0; i < data.length; i++) {
                            if ("GoAHeadEn" === data[i][0]) {
                                index = i;
                            }
                        }

                        data = data.slice(index + 1);
                        for (let i = 0; i < data.length; i++) {
                            let param = data[i][1].slice(24);

                            if (param == locat.search) {
                                this.feedbackData.paramName = data[i][0];
                            }
                        }
                    }
                } catch (err) {
                    console.log(err);
                }
            },
        },
        mounted() {
            this.getData();
        },
    };
</script>

<style scoped lang="scss">
    .company-logo {
        margin-top: 20px;
    }
    .app-wrap {
        margin-top: 20px;
        padding: 56px 94px 60px 64px;
        .text-primary {
            font-size: 40px;
            font-weight: normal;
            margin-bottom: 40px;
        }
        .feedback-container {
            &::before {
                content: "*";
                color: #ee732e;
                display: flex;
                position: absolute;
                top: px;
                height: 40px;
                width: 20px;
                align-items: center;
            }
            .label {
                display: none;
            }
        }
        .feedback-textarea {
            padding-left: 20px;
            margin-bottom: 60px;
        }
        .red {
            color: #ee732e;
        }
        .form-bottom {
            display: flex;
            justify-content: space-between;
            align-items: center;
            &__submit-button {
                margin: 0;
            }
        }
    }
    @media screen and (max-width: 576px) {
        .app-wrap {
            padding: 20px 20px 40px 20px;
            font-family: "Noto Sans", sans-serif;
            .text-primary {
                font-size: 24px;
                text-align: center;
            }
            .feedback-container {
                &::before {
                    margin-left: 5px;
                    display: none;
                }
                .feedback-textarea::placeholder {
                    color: transparent;
                }
                .label {
                    display: block;
                    text-align: start;
                    margin-bottom: 5px;
                    font-size: 13.333px;
                    color: #afafaf;
                }
            }
            .form-bottom {
                flex-direction: column;
            }
        }
    }
</style>
