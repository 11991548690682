import { createApp } from 'vue'
import App from './App.vue'
import i18n from './i18n';
import router from './router';

import TextareaAutosize from 'vue-textarea-autosize'

// Vue.use(TextareaAutosize);

// Vue.config.productionTip = false

// new Vue({
//   render: h => h(App),
// }).$mount('#app')

i18n(createApp(App)).use(TextareaAutosize).use(router).mount('#app');