import i18next from 'i18next'
import I18NextVue from 'i18next-vue'
import ru from './locales/ru.json'
import en from './locales/en.json'

i18next
  .init({
    debug: process.env.NODE_ENV!== 'production',
    lng: process.env.VUE_APP_LANGUAGE,
    // lng: 'ru',
    resources: {
      en: {
        translation: {
          ...en
        }
      },
      ru: {
        translation: {
          ...ru
        }
      }
    }
  });

export default function (app) {
  app.use(I18NextVue, { i18next })
  return app
}