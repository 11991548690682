import { createRouter, createWebHistory } from "vue-router";
import Main from "../components/Main.vue";
import ShortForm from "../components/ShortForm.vue";

export default createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/short-form', component: ShortForm },
    { path: '/', component: Main },
  ]
})